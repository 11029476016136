import React from "react";

//Ant Design Elements
import Spin from "antd/lib/spin";

import("../../styles/Spinner.css" /* webpackChunkName: "Spinner" */);

class Spinner extends React.Component {
    render() {
        return (
            <div
                className={
                    "spinner " +
                    (this.props.type !== "undefined"
                        ? this.props.type + "-spinner"
                        : "") +
                    (this.props.hidden ? " hidden" : "")
                }
                id={this.props.id !== "undefined" ? this.props.id : ""}
            >
                <Spin
                    className="ant-spin-dot-spin"
                    indicator={
                        <img
                            width="95"
                            height="95"
                            src={
                                process.env.REACT_APP_SITE_URL +
                                "/img/icons/tyre.svg"
                            }
                            alt="Loading"
                        />
                    }
                />
            </div>
        );
    }
}

export default Spinner;
