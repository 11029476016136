import React from "react";
import { withRouter } from "react-router";
import ReactDOM from "react-dom";

//Ant Design Elements
import Drawer from "antd/lib/drawer";

import NewsletterForm from "./NewsletterForm";

class NewsletterFormDrawerClass extends React.Component {
    state = {
        drawerVisible: false,
        // Once set to True, the Drawer cannot be re-opened (stops it being annoying)
        drawerHasBeenClosed: false,
    };

    componentDidMount = () => {
        // After 5 minutes on a page, open the Drawer
        setTimeout(() => {
            this.setState({
                drawerVisible: true,
            });
        }, 300000);

        // Start the Exit Intent Trigger to show Modal when pointer exits the top of the window
        this.applyExitIntentTrigger(() =>
            this.setState({
                drawerVisible: true,
            })
        );
    };

    /**
     * This function was copied from EmailOctopus' newsletter subscription modal
     */
    applyExitIntentTrigger = (exitIntentCallback) => {
        // How close to the top of the screen (in pixels) the mouse should move to,
        // before we trigger the modal.
        const sensitivity = 20;

        // How long before we activate the exit intent logic in ms.
        // We may want the page to have been loaded for a certain amount of time
        // before showing any modals.
        const timer = 3_000;

        // The delay between the mouse leaving the screen and the modal being
        // shown. If the mouse re-enters the screen during the delay, the display
        // of the modal is cancelled.
        const MODAL_DISPLAY_DELAY = 500;

        // The timeout ID for the current (if any) setTimeout call to show the
        // modal. Can be used to cancel the display of the modal.
        let modalTimeoutId = null;

        setTimeout(attachEventListeners, timer);

        function attachEventListeners() {
            document.documentElement.addEventListener(
                "mouseleave",
                onMouseleave
            );

            document.documentElement.addEventListener(
                "mouseenter",
                onMouseenter
            );
        }

        /**
         * If the mouse leaves the screen via the top of the screen (as if the user
         * is getting ready to close the current tab), we queue the display of the
         * modal after the `MODAL_DISPLAY_DELAY`.
         */
        function onMouseleave(event) {
            if (event.clientY > sensitivity) {
                return;
            }

            modalTimeoutId = setTimeout(showModal, MODAL_DISPLAY_DELAY);
        }

        /**
         * Cancel the display of the modal, if the mouse moves back into the
         * screen sooner than `MODAL_DISPLAY_DELAY`.
         */
        function onMouseenter() {
            if (modalTimeoutId) {
                clearTimeout(modalTimeoutId);
                modalTimeoutId = null;
            }
        }

        function showModal() {
            exitIntentCallback();

            removeEventListeners();
        }

        function removeEventListeners() {
            document.documentElement.removeEventListener(
                "mouseleave",
                onMouseleave
            );

            document.documentElement.removeEventListener(
                "mouseenter",
                onMouseenter
            );
        }
    };

    render() {
        let NewsletterFormToRender = (
            <>
                <Drawer
                    title={false}
                    placement={"bottom"}
                    closable={true}
                    onClose={() => {
                        this.setState({
                            drawerVisible: false,
                            drawerHasBeenClosed: true,
                        });
                    }}
                    visible={
                        this.state.drawerVisible &&
                        !this.state.drawerHasBeenClosed
                    }
                    className="newsletter-drawer"
                >
                    <NewsletterForm
                        {...this.props}
                        callback={() => {
                            this.setState({
                                drawerVisible: false,
                                drawerHasBeenClosed: true,
                            });
                        }}
                    />
                </Drawer>
            </>
        );

        if (this.props.embed) {
            return ReactDOM.createPortal(
                NewsletterFormToRender,
                document.getElementById("react-newsletter-form")
            );
        }

        return NewsletterFormToRender;
    }
}

/*
const NewsletterFormDrawer = Form.create({ name: "newsletter_form" })(
    NewsletterFormDrawerClass
);
*/
export default withRouter(NewsletterFormDrawerClass);
