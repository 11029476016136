import React from "react";
import { withRouter } from "react-router";
import ReactDOM from "react-dom";

//Ant Design Elements
import Button from "antd/lib/button";
import Col from "antd/lib/col";
import Form from "antd/lib/form";
import Input from "antd/lib/input";
import Modal from "antd/lib/modal";
import Row from "antd/lib/row";

import api from "../../../helpers/api";

class PPlaterReadinessQuizClass extends React.Component {
    state = {
        saving: false,
        submitButtonDisabled: true,
        formHasBeenSubmitted: false,
        displayQuestion: 0,
        answer_1: null,
        answer_2: null,
        answer_3: null,
        answer_4: null,
        answer_5: null,
        answer_6: null,
        answer_7: null,
        answer_8: null,
        answer_9: null,
        answer_10: null,
    };

    validateForm = () => {
        this.props.form.validateFields((err, values) => {
            this.setState({
                submitButtonDisabled: (!err ? false : true),
            });
        });
    };

    goNextField = (e) => {
        if (e.key === "Enter") {
            e.preventDefault();
            var inputs = document.querySelectorAll(
                "#p_plater_readiness_quiz input, #p_plater_readiness_quiz textarea"
            );

            var lastElement = true;
            for (var i = 0; i < inputs.length; i++) {
                if (inputs[i] === e.target && !!inputs[i + 1]) {
                    lastElement = false;
                    document.activeElement.blur();
                    document.activeElement.removeAttribute("autofocus");

                    inputs[i + 1].setAttribute("autofocus", "autofocus");
                    inputs[i + 1].focus();
                }
            }
            if (lastElement) {
                this.submitForm();
            }
        }
    };

    submitForm = () => {
        this.props.form.validateFields((err, values) => {
            if (err) {
                return false;
            }

            this.setState(
                {
                    saving: true,
                },
                () => {
                    if (!!this.props.user.id) {
                        values.student_id = this.props.user.id;
                    }
                    // Merge State Data (Answers) into value
                    let merged = {...values, ...this.state};
                    
                    // Submit
                    api.post("p_plater_readiness_quiz", {
                        body: JSON.stringify(merged),
                    })
                    .then((data) => {
                        if (
                            typeof data.result === "object" &&
                            data.result.success
                        ) {
                            this.setState({
                                formHasBeenSubmitted: true,
                            });
                        } else {
                            Modal.error({
                                className: "unavailable-survey",
                                title: "Error!",
                                content:
                                    "Sorry, an error occurred. Please try again!",
                                icon: false,
                                closable: false,
                                keyboard: false,
                            });
                        }
                        this.setState({
                            saving: false,
                        });
                    })
                    .catch((error) => {
                        Modal.error({
                            className: "unavailable-survey",
                            title: "Error!",
                            content:
                                "Sorry, an error occurred. Please try again!",
                            icon: false,
                            closable: false,
                            keyboard: false,
                        });
                        this.setState({
                            saving: false,
                        });
                    });
                }
            );
        });
    };

    render() {
        const { getFieldDecorator } = this.props.form;

        let PPlaterReadinessQuizToRender = (
            <>
                <style>
                {"\
                #p_plater_readiness_quiz {\
                    background-color: #f3f5f8;\
                    padding: 20px;\
                }\
                .answers {\
                    height: 100%;\
                    display: flex;\
                    flex-direction: column;\
                }\
                .answers .ant-btn {\
                    width: 100%;\
                    margin: auto !important;\
                }\
                .answers .ant-btn:hover {\
                    box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.4);\
                }\
                "}
                </style>
                <Form
                    id="p_plater_readiness_quiz"
                >
                    <Row>
                        <Col
                            span={24}
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                height: "360px",
                                justifyContent: "center",
                            }}
                        >
                            {this.state.formHasBeenSubmitted ? (
                                <>
                                    <p className="heading-md">Thank You</p>
                                    <p className="paragraph-md">
                                        You will receive an email shortly with your results.
                                    </p>
                                </>
                            ) : (
                                <>
                                    {this.state.displayQuestion === 0 && (
                                        <>
                                            <p className="paragraph-xxl" style={{fontWeight:'normal', fontSize:'28px',textAlign:'center'}}>
                                                Take the next step of your driving journey and see if you are ready to be a P-plate driver.
                                            </p>
                                            <p className="paragraph-xxl" style={{fontWeight:'normal', fontSize:'28px',textAlign:'center'}}>
                                                Test your knowledge with 10 multiple choice questions now.
                                            </p>
                                            <Button
                                                style={{ fontSize: '25px', width: "100%" }}
                                                type="primary"
                                                htmlType="button"
                                                size="large"
                                                onClick={() => {
                                                    this.setState({
                                                        displayQuestion: 1,
                                                    });
                                                }}
                                                id="StartButton"
                                            >
                                                Start Quiz
                                            </Button>
                                        </>
                                    )}

                                    {this.state.displayQuestion === 1 && (
                                        <>
                                            <p className="heading-xs">1. If you are driving at 60km/h, how much space is needed to avoid a crash?</p>

                                            <div
                                                className="answers"
                                            >
                                                <Button
                                                    type="default"
                                                    htmlType="button"
                                                    size="large"
                                                    onClick={() => {
                                                        this.setState({
                                                            answer_1: 'A',
                                                            displayQuestion: 2,
                                                        });
                                                    }}
                                                >
                                                    A: 20m
                                                </Button>
                                                <Button
                                                    type="default"
                                                    htmlType="button"
                                                    size="large"
                                                    onClick={() => {
                                                        this.setState({
                                                            answer_1: 'B',
                                                            displayQuestion: 2,
                                                        });
                                                    }}
                                                >
                                                    B: 35m
                                                </Button>
                                                <Button
                                                    type="default"
                                                    htmlType="button"
                                                    size="large"
                                                    onClick={() => {
                                                        this.setState({
                                                            answer_1: 'C',
                                                            displayQuestion: 2,
                                                        });
                                                    }}
                                                >
                                                    C: 50m
                                                </Button>
                                                <Button
                                                    type="default"
                                                    htmlType="button"
                                                    size="large"
                                                    onClick={() => {
                                                        this.setState({
                                                            answer_1: 'D',
                                                            displayQuestion: 2,
                                                        });
                                                    }}
                                                >
                                                    D: 60m
                                                </Button>
                                            </div>
                                        </>
                                    )}

                                    {this.state.displayQuestion === 2 && (
                                        <>
                                            <p className="heading-xs">2. How close can you kerbside parallel park to another vehicle?</p>

                                            <div
                                                className="answers"
                                            >
                                                <Button
                                                    type="default"
                                                    htmlType="button"
                                                    size="large"
                                                    onClick={() => {
                                                        this.setState({
                                                            answer_2: 'A',
                                                            displayQuestion: 3,
                                                        });
                                                    }}
                                                >
                                                    A: You must leave at least 1 metre front and back.
                                                </Button>
                                                <Button
                                                    type="default"
                                                    htmlType="button"
                                                    size="large"
                                                    onClick={() => {
                                                        this.setState({
                                                            answer_2: 'B',
                                                            displayQuestion: 3,
                                                        });
                                                    }}
                                                >
                                                    B: You must leave at least 2 metres front and back.
                                                </Button>
                                                <Button
                                                    type="default"
                                                    htmlType="button"
                                                    size="large"
                                                    onClick={() => {
                                                        this.setState({
                                                            answer_2: 'C',
                                                            displayQuestion: 3,
                                                        });
                                                    }}
                                                >
                                                    C: You must leave at least 3 metres front and back.
                                                </Button>
                                                <Button
                                                    type="default"
                                                    htmlType="button"
                                                    size="large"
                                                    onClick={() => {
                                                        this.setState({
                                                            answer_2: 'D',
                                                            displayQuestion: 3,
                                                        });
                                                    }}
                                                >
                                                    D: You must leave at least 4 metres front and back.
                                                </Button>
                                            </div>
                                        </>
                                    )}

                                    {this.state.displayQuestion === 3 && (
                                        <>
                                            <p className="heading-xs">3. Where do you stop at a stop sign?</p>

                                            <div
                                                className="answers"
                                            >
                                                <Button
                                                    type="default"
                                                    htmlType="button"
                                                    size="large"
                                                    onClick={() => {
                                                        this.setState({
                                                            answer_3: 'A',
                                                            displayQuestion: 4,
                                                        });
                                                    }}
                                                >
                                                    A: Stop so that your back wheels are just behind the stop sign.
                                                </Button>
                                                <Button
                                                    type="default"
                                                    htmlType="button"
                                                    size="large"
                                                    onClick={() => {
                                                        this.setState({
                                                            answer_3: 'B',
                                                            displayQuestion: 4,
                                                        });
                                                    }}
                                                >
                                                    B: Stop so that you are behind the line, as close as possible to it.
                                                </Button>
                                                <Button
                                                    type="default"
                                                    htmlType="button"
                                                    size="large"
                                                    onClick={() => {
                                                        this.setState({
                                                            answer_3: 'C',
                                                            displayQuestion: 4,
                                                        });
                                                    }}
                                                >
                                                    C: Stop so that your back wheels are just behind the line.
                                                </Button>
                                                <Button
                                                    type="default"
                                                    htmlType="button"
                                                    size="large"
                                                    onClick={() => {
                                                        this.setState({
                                                            answer_3: 'D',
                                                            displayQuestion: 4,
                                                        });
                                                    }}
                                                >
                                                    D: Stop next to the stop sign.
                                                </Button>
                                            </div>
                                        </>
                                    )}

                                    {this.state.displayQuestion === 4 && (
                                        <>
                                            <p className="heading-xs">4. When driving along a tricky road (hilly/curvy), how many seconds should you stay behind the car in front?</p>

                                            <div
                                                className="answers"
                                            >
                                                <Button
                                                    type="default"
                                                    htmlType="button"
                                                    size="large"
                                                    onClick={() => {
                                                        this.setState({
                                                            answer_4: 'A',
                                                            displayQuestion: 5,
                                                        });
                                                    }}
                                                >
                                                    A: 2 Seconds
                                                </Button>
                                                <Button
                                                    type="default"
                                                    htmlType="button"
                                                    size="large"
                                                    onClick={() => {
                                                        this.setState({
                                                            answer_4: 'B',
                                                            displayQuestion: 5,
                                                        });
                                                    }}
                                                >
                                                    B: 3 Seconds
                                                </Button>
                                                <Button
                                                    type="default"
                                                    htmlType="button"
                                                    size="large"
                                                    onClick={() => {
                                                        this.setState({
                                                            answer_4: 'C',
                                                            displayQuestion: 5,
                                                        });
                                                    }}
                                                >
                                                    C: 5 Seconds
                                                </Button>
                                                <Button
                                                    type="default"
                                                    htmlType="button"
                                                    size="large"
                                                    onClick={() => {
                                                        this.setState({
                                                            answer_4: 'D',
                                                            displayQuestion: 5,
                                                        });
                                                    }}
                                                >
                                                    D: 7 Seconds
                                                </Button>
                                            </div>
                                        </>
                                    )}

                                    {this.state.displayQuestion === 5 && (
                                        <>
                                            <p className="heading-xs">5. What is the maximum speed a P1 (red P) licence holder can drive at?</p>

                                            <div
                                                className="answers"
                                            >
                                                <Button
                                                    type="default"
                                                    htmlType="button"
                                                    size="large"
                                                    onClick={() => {
                                                        this.setState({
                                                            answer_5: 'A',
                                                            displayQuestion: 6,
                                                        });
                                                    }}
                                                >
                                                    A: 80 km/h
                                                </Button>
                                                <Button
                                                    type="default"
                                                    htmlType="button"
                                                    size="large"
                                                    onClick={() => {
                                                        this.setState({
                                                            answer_5: 'B',
                                                            displayQuestion: 6,
                                                        });
                                                    }}
                                                >
                                                    B: 90 km/h
                                                </Button>
                                                <Button
                                                    type="default"
                                                    htmlType="button"
                                                    size="large"
                                                    onClick={() => {
                                                        this.setState({
                                                            answer_5: 'C',
                                                            displayQuestion: 6,
                                                        });
                                                    }}
                                                >
                                                    C: 100 km/h
                                                </Button>
                                                <Button
                                                    type="default"
                                                    htmlType="button"
                                                    size="large"
                                                    onClick={() => {
                                                        this.setState({
                                                            answer_5: 'D',
                                                            displayQuestion: 6,
                                                        });
                                                    }}
                                                >
                                                    D: 120 km/h
                                                </Button>
                                            </div>
                                        </>
                                    )}

                                    {this.state.displayQuestion === 6 && (
                                        <>
                                            <p className="heading-xs">6. If you get caught using your phone as a P1 (red P) licence holder, what will happen?</p>

                                            <div
                                                className="answers"
                                            >
                                                <Button
                                                    type="default"
                                                    htmlType="button"
                                                    size="large"
                                                    onClick={() => {
                                                        this.setState({
                                                            answer_6: 'A',
                                                            displayQuestion: 7,
                                                        });
                                                    }}
                                                >
                                                    A: You will get a warning.
                                                </Button>
                                                <Button
                                                    type="default"
                                                    htmlType="button"
                                                    size="large"
                                                    onClick={() => {
                                                        this.setState({
                                                            answer_6: 'B',
                                                            displayQuestion: 7,
                                                        });
                                                    }}
                                                >
                                                    B: You will lose 2 demerit points and get fined.
                                                </Button>
                                                <Button
                                                    type="default"
                                                    htmlType="button"
                                                    size="large"
                                                    onClick={() => {
                                                        this.setState({
                                                            answer_6: 'C',
                                                            displayQuestion: 7,
                                                        });
                                                    }}
                                                >
                                                    C: You will lose 5 demerit points and face a 3 month suspension.
                                                </Button>
                                                <Button
                                                    type="default"
                                                    htmlType="button"
                                                    size="large"
                                                    onClick={() => {
                                                        this.setState({
                                                            answer_6: 'D',
                                                            displayQuestion: 7,
                                                        });
                                                    }}
                                                >
                                                    D: You will get fined.
                                                </Button>
                                            </div>
                                        </>
                                    )}

                                    {this.state.displayQuestion === 7 && (
                                        <>
                                            <p className="heading-xs">7. Can you drive a manual car as a P1 (red P) licence holder?</p>

                                            <div
                                                className="answers"
                                            >
                                                <Button
                                                    type="default"
                                                    htmlType="button"
                                                    size="large"
                                                    onClick={() => {
                                                        this.setState({
                                                            answer_7: 'A',
                                                            displayQuestion: 8,
                                                        });
                                                    }}
                                                >
                                                    A: No, not at all.
                                                </Button>
                                                <Button
                                                    type="default"
                                                    htmlType="button"
                                                    size="large"
                                                    onClick={() => {
                                                        this.setState({
                                                            answer_7: 'B',
                                                            displayQuestion: 8,
                                                        });
                                                    }}
                                                >
                                                    B: Yes, only if you have had driving lessons in one.
                                                </Button>
                                                <Button
                                                    type="default"
                                                    htmlType="button"
                                                    size="large"
                                                    onClick={() => {
                                                        this.setState({
                                                            answer_7: 'C',
                                                            displayQuestion: 8,
                                                        });
                                                    }}
                                                >
                                                    C: Yes, only if you have passed your driving test with a manual car.
                                                </Button>
                                                <Button
                                                    type="default"
                                                    htmlType="button"
                                                    size="large"
                                                    onClick={() => {
                                                        this.setState({
                                                            answer_7: 'D',
                                                            displayQuestion: 8,
                                                        });
                                                    }}
                                                >
                                                    D: Yes, of course.
                                                </Button>
                                            </div>
                                        </>
                                    )}

                                    {this.state.displayQuestion === 8 && (
                                        <>
                                            <p className="heading-xs">8. How many passengers under the age of 21 can a P1 (red P) licence holder carry between 11PM and 5AM?</p>

                                            <div
                                                className="answers"
                                            >
                                                <Button
                                                    type="default"
                                                    htmlType="button"
                                                    size="large"
                                                    onClick={() => {
                                                        this.setState({
                                                            answer_8: 'A',
                                                            displayQuestion: 9,
                                                        });
                                                    }}
                                                >
                                                    A: 1
                                                </Button>
                                                <Button
                                                    type="default"
                                                    htmlType="button"
                                                    size="large"
                                                    onClick={() => {
                                                        this.setState({
                                                            answer_8: 'B',
                                                            displayQuestion: 9,
                                                        });
                                                    }}
                                                >
                                                    B: 2
                                                </Button>
                                                <Button
                                                    type="default"
                                                    htmlType="button"
                                                    size="large"
                                                    onClick={() => {
                                                        this.setState({
                                                            answer_8: 'C',
                                                            displayQuestion: 9,
                                                        });
                                                    }}
                                                >
                                                    C: 3
                                                </Button>
                                                <Button
                                                    type="default"
                                                    htmlType="button"
                                                    size="large"
                                                    onClick={() => {
                                                        this.setState({
                                                            answer_8: 'D',
                                                            displayQuestion: 9,
                                                        });
                                                    }}
                                                >
                                                    D: 4
                                                </Button>
                                            </div>
                                        </>
                                    )}

                                    {this.state.displayQuestion === 9 && (
                                        <>
                                            <p className="heading-xs">9. Which of the following is the most common crash type?</p>

                                            <div
                                                className="answers"
                                            >
                                                <Button
                                                    type="default"
                                                    htmlType="button"
                                                    size="large"
                                                    onClick={() => {
                                                        this.setState({
                                                            answer_9: 'A',
                                                            displayQuestion: 10,
                                                        });
                                                    }}
                                                >
                                                    A: Running off the road on a straight.
                                                </Button>
                                                <Button
                                                    type="default"
                                                    htmlType="button"
                                                    size="large"
                                                    onClick={() => {
                                                        this.setState({
                                                            answer_9: 'B',
                                                            displayQuestion: 10,
                                                        });
                                                    }}
                                                >
                                                    B: Running off the road on a curve.
                                                </Button>
                                                <Button
                                                    type="default"
                                                    htmlType="button"
                                                    size="large"
                                                    onClick={() => {
                                                        this.setState({
                                                            answer_9: 'C',
                                                            displayQuestion: 10,
                                                        });
                                                    }}
                                                >
                                                    C: Colliding with an oncoming vehicle.
                                                </Button>
                                                <Button
                                                    type="default"
                                                    htmlType="button"
                                                    size="large"
                                                    onClick={() => {
                                                        this.setState({
                                                            answer_9: 'D',
                                                            displayQuestion: 10,
                                                        });
                                                    }}
                                                >
                                                    D: Colliding with the rear of a vehicle.
                                                </Button>
                                            </div>
                                        </>
                                    )}

                                    {this.state.displayQuestion === 10 && (
                                        <>
                                            <p className="heading-xs">10. Can you use your phone while driving as a Learner, P1 or P2 driver?</p>

                                            <div
                                                className="answers"
                                            >
                                                <Button
                                                    type="default"
                                                    htmlType="button"
                                                    size="large"
                                                    onClick={() => {
                                                        this.setState({
                                                            answer_10: 'A',
                                                            displayQuestion: 11,
                                                        });
                                                    }}
                                                >
                                                    A: Yes, only when the car is stationary.
                                                </Button>
                                                <Button
                                                    type="default"
                                                    htmlType="button"
                                                    size="large"
                                                    onClick={() => {
                                                        this.setState({
                                                            answer_10: 'B',
                                                            displayQuestion: 11,
                                                        });
                                                    }}
                                                >
                                                    B: Yes, as long as it is on hands-free mode.
                                                </Button>
                                                <Button
                                                    type="default"
                                                    htmlType="button"
                                                    size="large"
                                                    onClick={() => {
                                                        this.setState({
                                                            answer_10: 'C',
                                                            displayQuestion: 11,
                                                        });
                                                    }}
                                                >
                                                    C: Yes, but only for navigation.
                                                </Button>
                                                <Button
                                                    type="default"
                                                    htmlType="button"
                                                    size="large"
                                                    onClick={() => {
                                                        this.setState({
                                                            answer_10: 'D',
                                                            displayQuestion: 11,
                                                        });
                                                    }}
                                                >
                                                    D: No, not at all.
                                                </Button>
                                            </div>
                                        </>
                                    )}

                                    {this.state.displayQuestion === 11 && (
                                        <>
                                            <p className="heading-xs">
                                                Now let's find out how you went!<br />
                                                Fill in your details below to receive your results.
                                            </p>

                                            <Form.Item
                                                label={false}
                                                style={{ width: "100%" }}
                                            >
                                                {getFieldDecorator("first_name", {
                                                    rules: [
                                                        {
                                                            required: true,
                                                            message: "Enter your first name.",
                                                            whitespace: true,
                                                        },
                                                        {
                                                            max: 60,
                                                            message:
                                                                "First Name cannot be more than 60 characters.",
                                                        },
                                                    ],
                                                    initialValue: !!this.props.user.id && !this.props.shell_student ? this.props.user.first_name : "",
                                                })(
                                                    <Input
                                                        autoFocus
                                                        maxLength={60}
                                                        size="large"
                                                        onKeyDown={this.goNextField}
                                                        enterKeyHint="next"
                                                        placeholder="First Name"
                                                        onKeyUp={this.validateForm}
                                                        disabled={this.state.saving}
                                                    />
                                                )}
                                            </Form.Item>

                                            <Form.Item
                                                label={false}
                                                style={{ width: "100%" }}
                                            >
                                                {getFieldDecorator("last_name", {
                                                    rules: [
                                                        {
                                                            required: true,
                                                            message: "Enter your last name.",
                                                            whitespace: true,
                                                        },
                                                        {
                                                            max: 60,
                                                            message:
                                                                "Last Name cannot be more than 60 characters.",
                                                        },
                                                    ],
                                                    initialValue: !!this.props.user.id && !this.props.shell_student ? this.props.user.last_name : "",
                                                })(
                                                    <Input
                                                        maxLength={60}
                                                        size="large"
                                                        onKeyDown={this.goNextField}
                                                        enterKeyHint="next"
                                                        placeholder="Last Name"
                                                        onKeyUp={this.validateForm}
                                                        disabled={this.state.saving}
                                                    />
                                                )}
                                            </Form.Item>

                                            <Form.Item
                                                label={false}
                                                style={{ width: "100%" }}
                                            >
                                                {getFieldDecorator("email", {
                                                    rules: [
                                                        {
                                                            required: true,
                                                            type: "email",
                                                            message: "Enter a valid Email.",
                                                            whitespace: true,
                                                        },
                                                        {
                                                            max: 60,
                                                            message: "Email cannot be more than 60 characters.",
                                                        },
                                                    ],
                                                    initialValue: !!this.props.user.id && !this.props.shell_student ? this.props.user.email : "",
                                                })(
                                                    <Input
                                                        type="email"
                                                        maxLength={60}
                                                        size="large"
                                                        onKeyDown={this.goNextField}
                                                        enterKeyHint="next"
                                                        placeholder="Email Address"
                                                        onKeyUp={this.validateForm}
                                                        disabled={this.state.saving}
                                                    />
                                                )}
                                            </Form.Item>

                                            <Form.Item style={{ width: "100%" }}>
                                                <Button
                                                    style={{ width: "100%" }}
                                                    type="primary"
                                                    htmlType="submit"
                                                    size="large"
                                                    onClick={() => this.submitForm()}
                                                    disabled={
                                                        this.state.submitButtonDisabled ||
                                                        this.state.saving
                                                    }
                                                    loading={this.state.saving}
                                                    id="DKTDownloadButton"
                                                >
                                                    Submit
                                                </Button>
                                            </Form.Item>
                                        </>
                                    )}
                                </>
                            )}
                        </Col>
                    </Row>
                </Form>
            </>
        );

        if (this.props.embed) {
            return ReactDOM.createPortal(
                PPlaterReadinessQuizToRender,
                document.getElementById("react-p-plater-readiness-quiz")
            );
        }

        return PPlaterReadinessQuizToRender;
    }
}

const PPlaterReadinessQuiz = Form.create({ name: "p_plater_readiness_quiz" })(
    PPlaterReadinessQuizClass
);
export default withRouter(PPlaterReadinessQuiz);
