import "whatwg-fetch";
import config from "../config";
import Cookies from "universal-cookie";

const cookies = new Cookies();

let retryAttempts = [];
let maxRetries = 5;

const isAuthToken = () => {
    return cookies.get(config.environmentShort + "AT") !== null;
};

const request =
    (method) =>
    async (resource, options = {}) => {
        let request_uid = Date.now() + "_" + Math.random();
        retryAttempts[request_uid] = 1;
        return await makeRequest(method, request_uid, resource, options);
    };

const makeRequest = async (method, request_uid, resource, options = {}) => {
    const { apiRoot } = config;

    if (!resource || !apiRoot) return null;
    return await fetch(`${apiRoot}/${resource}`, {
        method,
        headers: isAuthToken()
            ? {
                  Accept: "application/json",
                  "Content-Type": "application/json",
                  Authorization:
                      "Bearer " + cookies.get(config.environmentShort + "AT"),
              }
            : {
                  Accept: "application/json",
                  "Content-Type": "application/json",
              },
        ...options,
    })
        .then((result) => {
            if (result.ok) {
                return result.json();
            } else if (
                result.status.toString().substr(0, 1) === "5" &&
                retryAttempts[request_uid] < maxRetries
            ) {
                // Retry on a errors in the 500-599 range
                retryAttempts[request_uid]++;
                return makeRequest(method, request_uid, resource, options);
            } else {
                return result.text().then((bodyText) => {
                    if (bodyText === "" && result.statusText !== "") {
                        return (
                            '{"status":"NOK","result":{"error":"' +
                            result.statusText +
                            '"}}'
                        );
                    }
                    return (
                        '{"status":"NOK","result":{"error":"' + bodyText + '"}}'
                    );
                });
            }
        })
        .catch((error) => {
            return '{"status":"NOK","result":{"error":"Unknown Error"}}';
        });
};

const mapUrlParams = (paramsObject) => {
    return Object.keys(paramsObject)
        .map((key) => key + "=" + paramsObject[key])
        .join("&");
};

const API = {
    get: request("GET"),
    post: request("POST"),
    patch: request("PATCH"),
    put: request("PUT"),
    delete: request("DELETE"),
    mapUrlParams,
};

export default API;
