import React, { Suspense } from "react";
import { withRouter } from "react-router";

//Ant Design Elements
import Alert from "antd/lib/alert";
import Button from "antd/lib/button";
import Col from "antd/lib/col";
import Form from "antd/lib/form";
import Input from "antd/lib/input";
import Modal from "antd/lib/modal";
import Row from "antd/lib/row";
import Tabs from "antd/lib/tabs";

import api from "../../../helpers/api";
import config from "../../../config";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar, faTimes } from "@fortawesome/free-solid-svg-icons";

import GoogleLoginButton from "../../elements/Social/GoogleLoginButton";
import Spinner from "../../elements/Spinner";

import("../../../styles/LoginModal.css" /* webpackChunkName: "LoginModal" */);

class Login extends React.Component {
    state = {
        loading: false,
        showErrorMessage: false,
        errorMessage: null,
        signUpButtonDisabled: true,
        logInButtonDisabled: true,
        showReferralCodeInput: false,
        checkingReferralCode: false,
        referralCodeValid: null,
        checkingSocialLogin: false,
    };

    toggleSocialLoginCheck = () => {
        this.setState({
            checkingSocialLogin: !this.state.checkingSocialLogin,
        });
    };

    goNextField = (e) => {
        if (e.key === "Enter") {
            var inputs = null;
            if (this.props.loginModalTab === "login") {
                inputs = document.querySelectorAll("#login_form input");
            } else {
                inputs = document.querySelectorAll("#register_form input");
            }

            var lastElement = true;
            for (var i = 0; i < inputs.length; i++) {
                if (inputs[i] === e.target && !!inputs[i + 1]) {
                    lastElement = false;
                    document.activeElement.blur();
                    document.activeElement.removeAttribute("autofocus");

                    inputs[i + 1].setAttribute("autofocus", "autofocus");
                    inputs[i + 1].focus();
                }
            }
            if (lastElement) {
                this.submitLogin();
            }
        }
    };

    submitLogin = () => {
        let fieldsToValidate = null;
        if (this.props.loginModalTab === "login") {
            fieldsToValidate = ["email", "password"];
        } else if (this.props.loginModalTab === "forgot") {
            fieldsToValidate = ["forgot_email"];
        } else {
            fieldsToValidate = [
                "register_first_name",
                "register_last_name",
                "register_mobile_phone",
                "register_email",
                "register_password",
            ];
        }
        this.props.form.validateFields(
            fieldsToValidate,
            async (err, values) => {
                if (
                    err ||
                    this.state.referralCodeValid === false ||
                    !this.checkReferralCode()
                ) {
                    return false;
                }

                // Login
                if (this.props.loginModalTab === "login") {
                    this.setState(
                        {
                            loading: true,
                        },
                        async () => {
                            return api
                                .post("students/login", {
                                    body: JSON.stringify(values),
                                })
                                .then(async (res) => {
                                    //Convert to JSON in case we've received a string response
                                    if (typeof res === "string") {
                                        res = JSON.parse(res);
                                    }
                                    //Check for an error response (status of "NOK")
                                    if (
                                        res.status === "NOK" ||
                                        res.result.success === false
                                    ) {
                                        this.setState({
                                            showErrorMessage: true,
                                            errorMessage: res.result.error,
                                            loading: false,
                                        });
                                    } else {
                                        let previouslyAssignedDT = null;
                                        let previouslyAssignedDTData = null;
                                        if (this.props.shell_student) {
                                            previouslyAssignedDT = !!this.props.assignedDriverTrainer ? this.props.assignedDriverTrainer.id : null;
                                            previouslyAssignedDTData = !!this.props.assignedDriverTrainer ? this.props.assignedDriverTrainer : null;
                                        }
                                        this.props.sendPageviewToGoogleAnalytics(
                                            "/virtual/bookings-new/user-details/login"
                                        );
                                        this.props.sendEventToGoogleTagManager({
                                            event: "user-login",
                                        });
                                        // Smartlook - Track User Login
                                        if (
                                            window.location.hostname ===
                                                "app.ltrent.com.au" &&
                                            typeof smartlook !== "undefined" &&
                                            typeof smartlook === "function"
                                        ) {
                                            // eslint-disable-next-line
                                            /*global smartlook*/ // This line prevents the below throwing an error when not on production
                                            smartlook("track", "user", {
                                                type: "login",
                                            });
                                        }

                                        // Set all of our App Level properties
                                        // If this is not a prelim flagged user, set the DT
                                        if (
                                            !this.props.prelim &&
                                            !this.props.shell_student
                                        ) {
                                            this.props.setAppStateValue(
                                                "assignedDriverTrainer",
                                                {
                                                    id: res.result.user.student.driver_trainer_id,
                                                    display_name: res.result.user.student?.driver_trainer === null ? null : res.result.user.student?.driver_trainer?.display_name,
                                                    headshot: typeof res.result.user.student?.driver_trainer?.website_headshot !=="undefined" &&
                                                                res.result.user.student?.driver_trainer?.website_headshot !== null &&
                                                                res.result.user.student?.driver_trainer?.website_headshot !== ""
                                                                ? res.result.user.student?.driver_trainer?.website_headshot
                                                                :
                                                                    typeof res.result.user.student?.driver_trainer?.website_photo !== "undefined" &&
                                                                    res.result.user.student?.driver_trainer?.website_photo !== null &&
                                                                    res.result.user.student?.driver_trainer?.website_photo !== ""
                                                                    ? res.result.user.student?.driver_trainer?.website_photo
                                                                    : process.env.REACT_APP_SITE_URL + "/img/default-headshot.webp", // @todo: remove this fallback to using website_photo when we remove that field in favour of headshots
                                                    website_bio: res.result.user.student?.driver_trainer === null ? null : res.result.user.student?.driver_trainer?.website_bio,
                                                    transmissionType: res.result.user.student?.driver_trainer === null ? "A" : res.result.user.student?.driver_trainer?.vehicle_transmission,
                                                },
                                                true
                                            );
                                        }

                                        this.props.setAppStateValue(
                                            "timestamps",
                                            {
                                                user: res.result.user.modified,
                                                student:
                                                    res.result.user.student
                                                        .modified,
                                            },
                                            true
                                        );
                                        if (
                                            typeof res.result.user.address !==
                                                "undefined" &&
                                            res.result.user.address !== null
                                        ) {
                                            this.props.setAppStateValue(
                                                "pickupAddressString",
                                                res.result.user.address
                                                    .street_number +
                                                    " " +
                                                    res.result.user.address
                                                        .street_name +
                                                    ", " +
                                                    res.result.user.address
                                                        .suburb
                                                        .suburb_full_details
                                            );
                                            this.props.setAppStateValue(
                                                "pickup_address_id",
                                                res.result.user.address.id
                                            );
                                            this.props.setAppStateValue(
                                                "pickup_address_postcode",
                                                res.result.user.address.suburb
                                                    .post_code
                                            );
                                            this.props.setAppStateValue(
                                                "pickup_address_state",
                                                res.result.user.address.suburb
                                                    .states.name
                                            );
                                            this.props.setAppStateValue(
                                                "pickup_address_street_name",
                                                res.result.user.address
                                                    .street_name
                                            );
                                            this.props.setAppStateValue(
                                                "pickup_address_street_number",
                                                res.result.user.address
                                                    .street_number
                                            );
                                            this.props.setAppStateValue(
                                                "pickup_address_suburb_id",
                                                res.result.user.address
                                                    .suburb_id
                                            );
                                            this.props.setAppStateValue(
                                                "pickup_address_suburb_name",
                                                res.result.user.address.suburb
                                                    .suburb_name
                                            );
                                        }
                                        this.props.setAppStateValue(
                                            "transmissionType",
                                            res.result.user.student
                                                ?.driver_trainer === null
                                                ? "A"
                                                : res.result.user.student
                                                      ?.driver_trainer
                                                      ?.vehicle_transmission
                                        );
                                        this.props.setAppStateValue(
                                            "existingUser",
                                            true
                                        );
                                        this.props.setAppStateValue(
                                            "completedLesson",
                                            typeof res.result.user
                                                .completed_lessons ===
                                                "undefined" ||
                                                res.result.user
                                                    .completed_lessons ===
                                                    null ||
                                                parseInt(
                                                    res.result.user
                                                        .completed_lessons
                                                ) === 0
                                                ? false
                                                : true
                                        );
                                        this.props.setAppStateValue(
                                            "futureLessons",
                                            typeof res.result.user
                                                .has_future_lessons ===
                                                "undefined" ||
                                                res.result.user
                                                    .has_future_lessons === null
                                                ? false
                                                : res.result.user
                                                      .has_future_lessons
                                        );
                                        this.props.setAppStateValue(
                                            "referringStudentName",
                                            (typeof res.result.user.referring_student_name === "undefined" || res.result.user.referring_student_name === null)
                                                ? ""
                                                : res.result.user.referring_student_name
                                        );

                                        this.props.cookies.set(
                                            config.environmentShort + "AT",
                                            res.result.access_token,
                                            {
                                                path: "/",
                                                domain: config.siteDomain,
                                            }
                                        );
                                        this.props.setAppStateValue(
                                            "prepaidData",
                                            {
                                                available: parseFloat(
                                                    res.result.user?.credits
                                                        ?.remaining
                                                ),
                                                using: 0,
                                                remaining: 0,
                                                owing: 0,
                                                mod1: !!res.result.user.credits
                                                    ?.sdc_remaining
                                                    ? parseInt(
                                                          res.result.user
                                                              .credits
                                                              .sdc_remaining[10]
                                                      )
                                                    : 0,
                                                mod2: !!res.result.user.credits
                                                    ?.sdc_remaining
                                                    ? parseInt(
                                                          res.result.user
                                                              .credits
                                                              .sdc_remaining[11]
                                                      )
                                                    : 0,
                                            },
                                            true
                                        );
                                        this.props.setAppStateValue(
                                            "user",
                                            {
                                                id: res.result.user.id,
                                                email: res.result.user.email,
                                                mobile_phone:
                                                    res.result.user
                                                        .mobile_phone,
                                                first_name:
                                                    res.result.user.first_name,
                                                last_name:
                                                    res.result.user.last_name,
                                                licence_number:
                                                    res.result.user.student
                                                        .licence_number,
                                                hours: res.result.user.student
                                                    .driving_experience,
                                                dob: res.result.user.dob,
                                                hadSDC: res.result.user.hadSDC,
                                                referral_code:
                                                    res.result.user
                                                        .referral_code,
                                                hide_cash:
                                                    res.result.user.student
                                                        .hide_cash_on_website,
                                            },
                                            false,
                                            async () => {
                                                this.props.setAppStateValue(
                                                    "pickupAddressChosen",
                                                    typeof res.result.user
                                                        .address ===
                                                        "undefined" &&
                                                        res.result.user
                                                            .address !== null
                                                        ? false
                                                        : true
                                                );

                                                if (
                                                    this.props
                                                        .completedLesson ||
                                                    this.props.futureLessons
                                                ) {
                                                    if (
                                                        config.showCompetition
                                                    ) {
                                                        this.props.addCustomBanner(
                                                            "referral",
                                                            '<strong>Refer a friend. Win a car!</strong> - Ltrent is giving away a brand new MG3. <a target="_blank" rel="noreferrer" href="' +
                                                                config.publicSiteUrl +
                                                                '/promos/win-a-car/">Learn More</a>',
                                                            ""
                                                        );
                                                    }
                                                } else if (
                                                    res.result.user
                                                        .referring_student_name
                                                ) {
                                                    this.props.addCustomBanner(
                                                        "referral",
                                                        "You have been referred by <strong>" +
                                                            res.result.user
                                                                .referring_student_name +
                                                            "</strong>. Complete your booking for a $20 discount on your next lesson!",
                                                        ""
                                                    );
                                                } else if (
                                                    this.props
                                                        .studentReferralCode &&
                                                    this.props
                                                        .studentReferralCode !==
                                                        this.props?.user
                                                            ?.referral_code
                                                ) {
                                                    this.verifyReferralCode(
                                                        this.props
                                                            .studentReferralCode,
                                                        false
                                                    );

                                                    this.addReferralCodeToExistingAccount(
                                                        this.props
                                                            .studentReferralCode,
                                                        res.result.user.id
                                                    );
                                                }

                                                // Clear Cart if Assigned DT Clash:
                                                let dtClash = false;
                                                // Check for NO Assigned DT.. that's actually okay, but we'll need to assign THIS DT
                                                if (
                                                    this.props.shell_student &&
                                                    res.result.user.student.driver_trainer_id === null
                                                ) {
                                                    // Assign the selected DT to the Student
                                                    api.post("students/assignDT", {
                                                        body: JSON.stringify({
                                                            student_id: res.result.user.id,
                                                            driver_trainer_id: previouslyAssignedDT,
                                                        }),
                                                    }).catch((error) => {
                                                        console.log(error);
                                                    });
                                                    // Set the previouslyAssignedDT back into State, in case it had been overwritten
                                                    this.props.setAppStateValue("assignedDriverTrainer", {
                                                        id: previouslyAssignedDTData.id,
                                                        display_name: previouslyAssignedDTData.display_name,
                                                        headshot: previouslyAssignedDTData.headshot,
                                                        website_bio: previouslyAssignedDTData.website_bio,
                                                        transmissionType: previouslyAssignedDTData.transmissionType,
                                                    }, true);
                                                }
                                                // Check for Assigned DT != Current DT.. Clear Cart and Alert User
                                                else if (
                                                    this.props.shell_student && previouslyAssignedDT !== null &&
                                                    parseInt(previouslyAssignedDT) !== parseInt(res.result.user.student.driver_trainer_id)
                                                ) {
                                                    // Set dtClash = true to throw error later
                                                    dtClash = true;
                                                    this.props.clearReservations();
                                                    this.props.setAppStateValue(
                                                        "cart",
                                                        {},
                                                        true
                                                    );
                                                    this.props.setAppStateValue(
                                                        "bookings",
                                                        {},
                                                        true
                                                    );
                                                    this.props.setAppStateValue(
                                                        "bookingsSDC",
                                                        {},
                                                        true
                                                    );
                                                    // Force the Assigned DT to the Student's Assigned DT
                                                    this.props.setAppStateValue(
                                                        "assignedDriverTrainer",
                                                        {
                                                            id: res.result.user
                                                                .student
                                                                .driver_trainer_id,
                                                            display_name:
                                                                res.result.user
                                                                    .student
                                                                    ?.driver_trainer ===
                                                                null
                                                                    ? null
                                                                    : res.result
                                                                          .user
                                                                          .student
                                                                          ?.driver_trainer
                                                                          ?.display_name,
                                                            headshot:
                                                                typeof res
                                                                    .result.user
                                                                    .student
                                                                    ?.driver_trainer
                                                                    ?.website_headshot !==
                                                                    "undefined" &&
                                                                res.result.user
                                                                    .student
                                                                    ?.driver_trainer
                                                                    ?.website_headshot !==
                                                                    null &&
                                                                res.result.user
                                                                    .student
                                                                    ?.driver_trainer
                                                                    ?.website_headshot !==
                                                                    ""
                                                                    ? res.result
                                                                          .user
                                                                          .student
                                                                          ?.driver_trainer
                                                                          ?.website_headshot
                                                                    : typeof res
                                                                          .result
                                                                          .user
                                                                          .student
                                                                          ?.driver_trainer
                                                                          ?.website_photo !==
                                                                          "undefined" &&
                                                                      res.result
                                                                          .user
                                                                          .student
                                                                          ?.driver_trainer
                                                                          ?.website_photo !==
                                                                          null &&
                                                                      res.result
                                                                          .user
                                                                          .student
                                                                          ?.driver_trainer
                                                                          ?.website_photo !==
                                                                          ""
                                                                    ? res.result
                                                                          .user
                                                                          .student
                                                                          ?.driver_trainer
                                                                          ?.website_photo
                                                                    : process
                                                                          .env
                                                                          .REACT_APP_SITE_URL +
                                                                      "/img/default-headshot.webp", // @todo: remove this fallback to using website_photo when we remove that field in favour of headshots
                                                            website_bio:
                                                                res.result.user
                                                                    .student
                                                                    ?.driver_trainer ===
                                                                null
                                                                    ? null
                                                                    : res.result
                                                                          .user
                                                                          .student
                                                                          ?.driver_trainer
                                                                          ?.website_bio,
                                                            transmissionType:
                                                                res.result.user
                                                                    .student
                                                                    ?.driver_trainer ===
                                                                null
                                                                    ? "A"
                                                                    : res.result
                                                                          .user
                                                                          .student
                                                                          ?.driver_trainer
                                                                          ?.vehicle_transmission,
                                                        },
                                                        true
                                                    );
                                                }

                                                // Check for Student Clashes (is logged in Student busy at any of the Reserved times?)
                                                let studentClash = false;
                                                if (
                                                    !dtClash &&
                                                    this.props.shell_student
                                                ) {
                                                    let clashingBookings =
                                                        await this.props.checkStudentClashes(
                                                            res.result.user.id
                                                        );
                                                    if (
                                                        clashingBookings.length >
                                                        0
                                                    ) {
                                                        // Cancel any Unavailable ones, remove from Bookings object
                                                        let cloneBookings = {
                                                            ...this.props
                                                                .bookings,
                                                        };

                                                        // Loop through and Cancel any Reservations that Clash
                                                        Object.values(
                                                            clashingBookings
                                                        ).forEach(
                                                            (booking_id) => {
                                                                if (
                                                                    !!cloneBookings[
                                                                        booking_id
                                                                    ]
                                                                ) {
                                                                    // Remove from Bookings object
                                                                    delete cloneBookings[
                                                                        booking_id
                                                                    ];
                                                                    // Send Cancel Reservation API Request - We don't need to wait for a response - just assume it's ok
                                                                    api.post(
                                                                        "bookings/cancelReservation",
                                                                        {
                                                                            body: JSON.stringify(
                                                                                {
                                                                                    booking:
                                                                                        booking_id,
                                                                                    student: true,
                                                                                }
                                                                            ),
                                                                        }
                                                                    ).then(
                                                                        () => {
                                                                            this.props.sendEventToGoogleTagManager(
                                                                                {
                                                                                    event: "cart-removal-driving-lesson",
                                                                                }
                                                                            );
                                                                        }
                                                                    );
                                                                }
                                                            }
                                                        );
                                                        // Update State
                                                        this.props.updateBookingsDuration();
                                                        this.props.setAppStateValue(
                                                            "bookings",
                                                            cloneBookings,
                                                            true
                                                        );

                                                        // Alert Student
                                                        studentClash = true;
                                                    }
                                                }

                                                // Clear Shell Student status, we're now logged in.
                                                this.props.setAppStateValue(
                                                    "shell_student",
                                                    false
                                                );

                                                // Hide Login Modal
                                                this.props.setAppStateValue(
                                                    "loginModalVisible",
                                                    false
                                                );

                                                // Start Student TimeStamp Polling
                                                this.props.startStudentTimeStampChecker();

                                                // Finish Up - Either show an Error, or process the supplied Callback
                                                if (
                                                    !studentClash &&
                                                    !dtClash &&
                                                    typeof this.props
                                                        .loginModalCallback ===
                                                        "function"
                                                ) {
                                                    this.props.loginModalCallback();
                                                } else if (dtClash) {
                                                    Modal.error({
                                                        icon: false,
                                                        title: false,
                                                        content: (
                                                            <>
                                                                <p className="heading-xs">
                                                                    Welcome
                                                                    back!
                                                                </p>
                                                                <p className="paragraph-lg">
                                                                    Sorry, the
                                                                    reservation(s)
                                                                    you had did
                                                                    not match
                                                                    your
                                                                    Assigned
                                                                    Driver
                                                                    Trainer.
                                                                </p>
                                                                <p className="paragraph-lg">
                                                                    Please try
                                                                    again. The
                                                                    available
                                                                    times shown
                                                                    will now be
                                                                    those of
                                                                    your Driver
                                                                    Trainer.
                                                                </p>
                                                                <p className="paragraph-lg">
                                                                    If you are
                                                                    having
                                                                    trouble
                                                                    booking,
                                                                    please{" "}
                                                                    <a
                                                                        href={
                                                                            config.publicSiteUrl +
                                                                            "/locations-and-contact-us/"
                                                                        }
                                                                        target="_blank"
                                                                        rel="noreferrer"
                                                                    >
                                                                        contact
                                                                        our
                                                                        office
                                                                    </a>
                                                                    .
                                                                </p>
                                                            </>
                                                        ),
                                                    });
                                                } else if (studentClash) {
                                                    Modal.error({
                                                        icon: false,
                                                        title: false,
                                                        content: (
                                                            <>
                                                                <p className="heading-xs">
                                                                    Welcome
                                                                    back!
                                                                </p>
                                                                <p className="paragraph-lg">
                                                                    Sorry, some
                                                                    of the
                                                                    Reservations
                                                                    you had in
                                                                    your Cart
                                                                    clashed with
                                                                    other
                                                                    Bookings in
                                                                    your
                                                                    account.
                                                                </p>
                                                                <p className="paragraph-lg">
                                                                    Please try
                                                                    again. The
                                                                    times shown
                                                                    will now be
                                                                    those where
                                                                    both you and
                                                                    your Driver
                                                                    Trainer are
                                                                    available.
                                                                </p>
                                                                <p className="paragraph-lg">
                                                                    If you are
                                                                    having
                                                                    trouble
                                                                    booking,
                                                                    please{" "}
                                                                    <a
                                                                        href={
                                                                            config.publicSiteUrl +
                                                                            "/locations-and-contact-us/"
                                                                        }
                                                                        target="_blank"
                                                                        rel="noreferrer"
                                                                    >
                                                                        contact
                                                                        our
                                                                        office
                                                                    </a>
                                                                    .
                                                                </p>
                                                            </>
                                                        ),
                                                    });
                                                }
                                            }
                                        );
                                    }
                                })
                                .catch((error) => {
                                    this.setState({
                                        loading: false,
                                        showErrorMessage: true,
                                        errorMessage:
                                            "Error logging in. Please try again.",
                                    });
                                });
                        }
                    );
                } else if (this.props.loginModalTab === "forgot") {
                    // Forgot Password - Sends Reset Email
                    this.setState(
                        {
                            loading: true,
                            showErrorMessage: false,
                            errorMessage: null,
                        },
                        () => {
                            return api
                                .post("students/reset-password", {
                                    body: JSON.stringify({
                                        email: values.forgot_email,
                                        site: "APP",
                                    }),
                                })
                                .then((res) => {
                                    //Convert to JSON in case we've received a string response
                                    if (typeof res === "string") {
                                        res = JSON.parse(res);
                                    }
                                    //Check for an error response (status of "NOK")
                                    if (
                                        res.status === "NOK" ||
                                        res.result.success === false
                                    ) {
                                        this.setState({
                                            showErrorMessage: true,
                                            errorMessage: res.result.error,
                                            loading: false,
                                        });
                                    } else {
                                        this.props.sendEventToGoogleTagManager({
                                            event: "user-forgot-password",
                                        });
                                        Modal.success({
                                            title: false,
                                            content: (
                                                <div>
                                                    <p>{res.result.message}</p>
                                                </div>
                                            ),
                                            closable: false,
                                            keyboard: false,
                                            onOk: () => {
                                                this.setState(
                                                    {
                                                        showErrorMessage: false,
                                                        errorMessage: null,
                                                        loading: false,
                                                    },
                                                    () => {
                                                        // Close the Login Modal.
                                                        this.props.loginModalToggle();
                                                    }
                                                );
                                            },
                                        });
                                    }
                                });
                        }
                    );
                } else {
                    if (this.state.referralCodeValid === false) {
                        return false;
                    }

                    // Sign Up - Check existing student first
                    this.setState(
                        {
                            loading: true,
                        },
                        () => {
                            // Check for Existing Student
                            this.props.checkStudentExists(
                                () => {
                                    // Rename variables:
                                    values.email = values.register_email;
                                    values.first_name =
                                        values.register_first_name;
                                    values.last_name =
                                        values.register_last_name;
                                    values.mobile_phone =
                                        values.register_mobile_phone;
                                    values.password = values.register_password;
                                    values.confirm = values.register_password;

                                    // Send the address from their first booking, and their assigned driver_trainer_id
                                    if (this.props.pickup_address_id !== null) {
                                        values.address_id =
                                            this.props.pickup_address_id;
                                    } else {
                                        values.pickup_suburb =
                                            this.props.pickup_address_suburb_id;
                                        values.pickup_address_street_number =
                                            this.props.pickup_address_street_number;
                                        values.pickup_address_street_name =
                                            this.props.pickup_address_street_name;
                                    }

                                    if (this.props.studentReferralCode) {
                                        values.referral_code =
                                            this.props.studentReferralCode;
                                    }

                                    if (this.props.driverReferralCode) {
                                        values.driver_referral_code =
                                            this.props.driverReferralCode;
                                    }

                                    values.source = "app";

                                    // Shell ?
                                    values.shell = this.props.shell_student;
                                    values.student_id = this.props.shell_student
                                        ? this.props.user.id
                                        : null;

                                    return api
                                        .post("students/save", {
                                            body: JSON.stringify(values),
                                        })
                                        .then((res) => {
                                            //Convert to JSON in case we've received a string response
                                            if (typeof res === "string") {
                                                res = JSON.parse(res);
                                            }
                                            //Check for an error response (status of "NOK")
                                            if (
                                                res.status === "NOK" ||
                                                res.result.success === false
                                            ) {
                                                this.setState({
                                                    showErrorMessage: true,
                                                    loading: false,
                                                });
                                            } else {
                                                if (!this.props.shell_student) {
                                                    // Save the access token
                                                    this.props.cookies.set(
                                                        config.environmentShort +
                                                            "AT",
                                                        res.result.access_token,
                                                        {
                                                            path: "/",
                                                            domain: config.siteDomain,
                                                        }
                                                    );
                                                    // Update state
                                                    this.props.setAppStateValue(
                                                        "user",
                                                        {
                                                            id: res.result
                                                                .student_id,
                                                        }
                                                    );
                                                } else {
                                                    // Update state
                                                    this.props.setAppStateValue(
                                                        "user",
                                                        {
                                                            email: res.result
                                                                .user.email,
                                                        }
                                                    );
                                                    this.props.setAppStateValue(
                                                        "user",
                                                        {
                                                            first_name:
                                                                res.result.user
                                                                    .first_name,
                                                        }
                                                    );
                                                    this.props.setAppStateValue(
                                                        "user",
                                                        {
                                                            last_name:
                                                                res.result.user
                                                                    .last_name,
                                                        }
                                                    );
                                                    this.props.setAppStateValue(
                                                        "user",
                                                        {
                                                            mobile_phone:
                                                                res.result.user
                                                                    .mobile_phone,
                                                        }
                                                    );
                                                }
                                                this.props.sendPageviewToGoogleAnalytics(
                                                    "/virtual/bookings-new/user-details/login"
                                                ); //First ping to signal login
                                                this.props.sendEventToGoogleTagManager(
                                                    {
                                                        event: "user-signup",
                                                    }
                                                );
                                                // Smartlook - Track User Registration
                                                if (
                                                    window.location.hostname ===
                                                        "app.ltrent.com.au" &&
                                                    typeof smartlook !==
                                                        "undefined" &&
                                                    typeof smartlook ===
                                                        "function"
                                                ) {
                                                    // eslint-disable-next-line
                                                    /*global smartlook*/ // This line prevents the below throwing an error when not on production
                                                    smartlook("track", "user", {
                                                        type: "registration",
                                                    });
                                                }
                                                // Call the callback function if one exists
                                                if (
                                                    typeof this.props
                                                        .loginModalCallback ===
                                                    "function"
                                                ) {
                                                    this.props.loginModalCallback();
                                                }
                                                // Start Student TimeStamp Polling
                                                this.props.startStudentTimeStampChecker();
                                            }
                                        })
                                        .catch((error) => {
                                            this.setState({
                                                showErrorMessage: true,
                                                loading: false,
                                            });
                                        });
                                },
                                values.register_email,
                                values.register_mobile_phone
                            );
                        }
                    );
                }
            }
        );
    };

    changeTab = (activeKey) => {
        this.setState(
            {
                showErrorMessage: false,
                errorMessage: null,
            },
            () => {
                this.props.setAppStateValue("loginModalTab", activeKey);
            }
        );
    };

    validateSignUpForm = () => {
        if (
            // All Fields Required:
            document.getElementById("login_form_register_first_name") !==
                null &&
            document.getElementById("login_form_register_first_name").value !==
                "" &&
            document.getElementById("login_form_register_last_name") !== null &&
            document.getElementById("login_form_register_last_name").value !==
                "" &&
            document.getElementById("login_form_register_mobile_phone") !==
                null &&
            document.getElementById("login_form_register_mobile_phone")
                .value !== "" &&
            document.getElementById("login_form_register_email") !== null &&
            document.getElementById("login_form_register_email").value !== "" &&
            document.getElementById("login_form_register_password") !== null &&
            document.getElementById("login_form_register_password").value !== ""
        ) {
            let fieldsToValidate = [
                "register_first_name",
                "register_last_name",
                "register_mobile_phone",
                "register_email",
                "register_password",
            ];
            this.props.form.validateFields(fieldsToValidate, (err, values) => {
                if (!err && this.state.referralCodeValid !== false) {
                    // No Errors : Disabled = False
                    this.setState({
                        signUpButtonDisabled: false,
                    });
                } else {
                    // Errors : Disabled = True
                    this.setState({
                        signUpButtonDisabled: true,
                    });
                }
            });
        } else {
            // Fields Empty : Disabled = True
            this.setState({
                signUpButtonDisabled: true,
            });
        }
    };

    validateLogInForm = () => {
        if (
            // All Fields Required:
            document.getElementById("login_form_email") !== null &&
            document.getElementById("login_form_email").value !== "" &&
            document.getElementById("login_form_password") !== null &&
            document.getElementById("login_form_password").value !== ""
        ) {
            let fieldsToValidate = ["email", "password"];
            this.props.form.validateFields(fieldsToValidate, (err, values) => {
                if (!err) {
                    // No Errors : Disabled = False
                    this.setState({
                        logInButtonDisabled: false,
                    });
                } else {
                    // Errors : Disabled = True
                    this.setState({
                        logInButtonDisabled: true,
                    });
                }
            });
        } else {
            // Fields Empty : Disabled = True
            this.setState({
                logInButtonDisabled: true,
            });
        }
    };

    addReferralCodeToExistingAccount = async (referralCode, user_id) => {
        return api
            .post("students/update/details", {
                body: JSON.stringify({
                    referral_code: referralCode,
                    source: "app",
                    id: user_id,
                }),
            })
            .then((res) => {
                //Convert to JSON in case we've received a string response
                if (typeof res === "string") {
                    res = JSON.parse(res);
                }

                return true;
            })
            .catch((error) => {
                return false;
            });
    };

    verifyReferralCode = (referralCode, isSignUp = false) => {
        api.post("students/verifyReferralCode", {
            body: JSON.stringify({
                referral_code: referralCode,
            }),
        })
            .then((res) => {
                //Convert to JSON in case we've received a string response
                if (typeof res === "string") {
                    res = JSON.parse(res);
                }

                let referringStudentName =
                    res.result.referrer.first_name +
                    " " +
                    res.result.referrer.last_name;

                this.props.setAppStateValue(
                    "studentReferralCode",
                    referralCode
                );
                this.props.setAppStateValue(
                    "referringStudentName",
                    referringStudentName,
                    true,
                    () => {
                        this.props.storeEncryptedState();
                    }
                );

                this.props.addCustomBanner(
                    "referral",
                    "You have been referred by <strong>" +
                        referringStudentName +
                        "</strong>. Complete your booking for a $20 discount on your next lesson!",
                    ""
                );

                if (isSignUp) {
                    this.setState(
                        {
                            checkingReferralCode: false,
                            referralCodeValid: true,
                            showReferralCodeInput: false,
                        },
                        () => this.validateSignUpForm()
                    );
                }

                return true;
            })
            .catch((error) => {
                this.props.setAppStateValue("referringStudentName", "");

                this.setState({
                    checkingReferralCode: false,
                    referralCodeValid: false,
                });

                return false;
            });
    };

    checkReferralCode = () => {
        if (
            document.getElementById("login_form_referral_code") &&
            document.getElementById("login_form_referral_code").value !== ""
        ) {
            this.setState({
                checkingReferralCode: true,
                referralCodeValid: null,
                signUpButtonDisabled: true,
            });

            let referralCode = document.getElementById(
                "login_form_referral_code"
            ).value;

            this.verifyReferralCode(referralCode, true);
        } else {
            this.setState({ referralCodeValid: null }, () =>
                this.validateSignUpForm()
            );
            return true;
        }
    };

    render() {
        const { getFieldDecorator } = this.props.form;

        return (
            <Modal
                wrapClassName={this.props.shell_student ? "shell_student" : ""}
                closeIcon={
                    <FontAwesomeIcon
                        fixedWidth
                        icon={faTimes}
                        style={{
                            color: "var(--grey-700)",
                            width: "24px",
                            height: "24px",
                        }}
                    />
                }
                className="login-modal"
                title={false}
                visible={this.props.loginModalVisible}
                onCancel={() => this.props.loginModalToggle()}
                footer={null}
                width={null}
                destroyOnClose
                style={{ top: 50 }}
            >
                <Row style={{ display: "flex" }}>
                    <Col
                        id="loginModalLeft"
                        xs={{ span: 24, offset: 0 }}
                        sm={{ span: 24, offset: 0 }}
                        md={{ span: 24, offset: 0 }}
                        lg={{
                            span: this.props.shell_student ? 24 : 12,
                            offset: 0,
                        }}
                        xl={{
                            span: this.props.shell_student ? 24 : 12,
                            offset: 0,
                        }}
                    >
                        {this.state.checkingSocialLogin ? (
                            <Suspense fallback={this.props.renderLoader()}>
                                <Spinner type="mega" />
                            </Suspense>
                        ) : null}

                        <Tabs
                            tabPosition="bottom"
                            activeKey={this.props.loginModalTab}
                            onChange={this.changeTab}
                        >
                            <Tabs.TabPane
                                tab="Register Your Student Details"
                                key="register"
                            >
                                <p
                                    className="heading-sm"
                                    style={{ marginBottom: "0.25em" }}
                                >
                                    Register Your Student Details
                                </p>
                                <Form id="register_form">
                                    <p
                                        className="paragraph-sm"
                                        style={{ margin: 0, marginBottom: 10 }}
                                    >
                                        {this.props.shell_student ? (
                                            <>
                                                You're only 1 step away from
                                                confirming your booking!
                                            </>
                                        ) : (
                                            <>
                                                Please complete this short
                                                registration form to be paired
                                                with your personal driving
                                                instructor.
                                            </>
                                        )}
                                    </p>
                                    <p
                                        className="paragraph-md"
                                        style={{
                                            margin: 0,
                                            textAlign: "center",
                                            fontWeight: "bold",
                                        }}
                                    >
                                        Existing Student?{" "}
                                        <span
                                            className="changeTab red-text"
                                            onClick={() =>
                                                this.changeTab("login")
                                            }
                                        >
                                            Log In
                                        </span>
                                    </p>

                                    <hr className="gradient closeMargin" />

                                    <div>
                                        <Form.Item
                                            label="Student Name"
                                            className="halfWidth"
                                        >
                                            {getFieldDecorator(
                                                "register_first_name",
                                                {
                                                    rules: [
                                                        {
                                                            required: true,
                                                            message:
                                                                "Enter your First Name.",
                                                            whitespace: true,
                                                        },
                                                        {
                                                            max: 30,
                                                            message:
                                                                "First Name cannot be more than 30 characters.",
                                                        },
                                                    ],
                                                }
                                            )(
                                                <Input
                                                    autoFocus
                                                    maxLength={30}
                                                    size="large"
                                                    onKeyDown={this.goNextField}
                                                    enterKeyHint="next"
                                                    placeholder="First Name"
                                                    readOnly={
                                                        this.props
                                                            .connectedWithFacebook ||
                                                        this.props
                                                            .connectedWithGoogle
                                                    }
                                                    onBlur={
                                                        this.validateSignUpForm
                                                    }
                                                />
                                            )}
                                        </Form.Item>
                                        <Form.Item
                                            label="Student Name"
                                            className="halfWidth"
                                        >
                                            {getFieldDecorator(
                                                "register_last_name",
                                                {
                                                    rules: [
                                                        {
                                                            required: true,
                                                            message:
                                                                "Enter your Last Name.",
                                                            whitespace: true,
                                                        },
                                                        {
                                                            max: 30,
                                                            message:
                                                                "Last Name cannot be more than 30 characters.",
                                                        },
                                                    ],
                                                }
                                            )(
                                                <Input
                                                    maxLength={30}
                                                    size="large"
                                                    onKeyDown={this.goNextField}
                                                    enterKeyHint="next"
                                                    placeholder="Last Name"
                                                    readOnly={
                                                        this.props
                                                            .connectedWithFacebook ||
                                                        this.props
                                                            .connectedWithGoogle
                                                    }
                                                    onBlur={
                                                        this.validateSignUpForm
                                                    }
                                                />
                                            )}
                                        </Form.Item>
                                    </div>
                                    <Form.Item label="Mobile Phone">
                                        {getFieldDecorator(
                                            "register_mobile_phone",
                                            {
                                                getValueFromEvent: (
                                                    e: React.FormEvent<HTMLInputElement>
                                                ) => {
                                                    if (
                                                        isNaN(
                                                            Number(
                                                                e.target.value
                                                            )
                                                        )
                                                    ) {
                                                        return this.props.form.getFieldValue(
                                                            "register_mobile_phone"
                                                        );
                                                    } else {
                                                        return e.target.value;
                                                    }
                                                },
                                                rules: [
                                                    {
                                                        required: true,
                                                        message:
                                                            "Enter your Mobile Number.",
                                                        whitespace: true,
                                                    },
                                                    {
                                                        min: 10,
                                                        message:
                                                            "Mobile Number must be 10 characters.",
                                                    },
                                                ],
                                            }
                                        )(
                                            <Input
                                                size="large"
                                                onKeyDown={this.goNextField}
                                                enterKeyHint="next"
                                                placeholder="Mobile Phone"
                                                type="tel"
                                                maxLength={10}
                                                onBlur={this.validateSignUpForm}
                                            />
                                        )}
                                    </Form.Item>

                                    <Form.Item label="Email">
                                        {getFieldDecorator("register_email", {
                                            rules: [
                                                {
                                                    required: true,
                                                    type: "email",
                                                    message:
                                                        "Enter a valid Email.",
                                                    whitespace: true,
                                                },
                                                {
                                                    max: 60,
                                                    message:
                                                        "Email cannot be more than 60 characters.",
                                                },
                                            ],
                                        })(
                                            <Input
                                                type="email"
                                                maxLength={60}
                                                size="large"
                                                onKeyDown={this.goNextField}
                                                enterKeyHint="next"
                                                placeholder="Enter your Email"
                                                onBlur={this.validateSignUpForm}
                                            />
                                        )}
                                    </Form.Item>
                                    <Form.Item label="Password">
                                        {getFieldDecorator(
                                            "register_password",
                                            {
                                                rules: [
                                                    {
                                                        required: true,
                                                        message:
                                                            "Password must be at least 6 characters.",
                                                        whitespace: true,
                                                    },
                                                    {
                                                        min: 6,
                                                        message:
                                                            "Password must be at least 6 characters.",
                                                    },
                                                ],
                                            }
                                        )(
                                            <Input.Password
                                                type="password"
                                                size="large"
                                                onKeyDown={this.goNextField}
                                                enterKeyHint="next"
                                                placeholder="Enter your Password"
                                                onKeyUp={
                                                    this.validateSignUpForm
                                                }
                                            />
                                        )}
                                    </Form.Item>

                                    {this.props.studentReferralCode &&
                                        this.props.referringStudentName && (
                                            <Form.Item>
                                                <strong>
                                                    You have been referred by{" "}
                                                    {
                                                        this.props
                                                            .referringStudentName
                                                    }
                                                </strong>
                                                <br />
                                                <span>
                                                    Receive a $20 discount
                                                    voucher after you complete
                                                    your first lesson.
                                                </span>
                                            </Form.Item>
                                        )}

                                    {!(
                                        this.props.studentReferralCode &&
                                        this.props.referringStudentName
                                    ) &&
                                        !this.state.showReferralCodeInput && (
                                            <Form.Item>
                                                <span
                                                    className="changeTab red-text"
                                                    onClick={() =>
                                                        this.setState({
                                                            showReferralCodeInput: true,
                                                        })
                                                    }
                                                >
                                                    Have a referral code?
                                                </span>
                                            </Form.Item>
                                        )}

                                    {this.state.showReferralCodeInput && (
                                        <Form.Item label="Referral Code">
                                            {getFieldDecorator(
                                                "referral_code",
                                                {}
                                            )(
                                                <Input
                                                    maxLength={8}
                                                    size="large"
                                                    onKeyDown={this.goNextField}
                                                    enterKeyHint="next"
                                                    placeholder="XXXXXXX"
                                                    onBlur={
                                                        this.checkReferralCode
                                                    }
                                                />
                                            )}
                                            {this.state
                                                .checkingReferralCode && (
                                                <Suspense
                                                    fallback={this.props.renderLoader()}
                                                >
                                                    <Spinner type="mini-inline" />{" "}
                                                    Checking Referral Code...
                                                </Suspense>
                                            )}
                                            {this.state.referralCodeValid ===
                                                false && (
                                                <Alert
                                                    style={{ display: "block" }}
                                                    key="error-message"
                                                    message="Referral Code is not Valid"
                                                    type="error"
                                                    showIcon={false}
                                                />
                                            )}
                                        </Form.Item>
                                    )}

                                    {this.state.showErrorMessage && (
                                        <div className="ant-alert-wrapper">
                                            <Alert
                                                style={{
                                                    display: this.state
                                                        .showErrorMessage
                                                        ? "block"
                                                        : "none",
                                                }}
                                                key="error-message"
                                                message={
                                                    this.state.errorMessage
                                                }
                                                type="error"
                                                showIcon={false}
                                            />
                                        </div>
                                    )}

                                    <Button
                                        type="primary"
                                        size="large"
                                        loading={this.state.loading}
                                        onClick={() => this.submitLogin()}
                                        disabled={
                                            this.state.signUpButtonDisabled
                                        }
                                    >
                                        Proceed
                                    </Button>

                                    <div className="registration-or">or</div>

                                    <div className="social-login-button-holder">
                                        <GoogleLoginButton
                                            {...this.props}
                                            context="Sign Up"
                                            toggleSocialLoginCheck={
                                                this.toggleSocialLoginCheck
                                            }
                                        />
                                    </div>
                                </Form>
                            </Tabs.TabPane>

                            <Tabs.TabPane tab="Log In" key="login">
                                <p
                                    className="heading-sm"
                                    style={{ marginBottom: "0.5em" }}
                                >
                                    Log In
                                </p>
                                <Form id="login_form">
                                    <p
                                        className="paragraph-md"
                                        style={{
                                            margin: 0,
                                            textAlign: "center",
                                            fontWeight: "bold",
                                        }}
                                    >
                                        Don't have an account?{" "}
                                        <span
                                            className="changeTab red-text"
                                            onClick={() =>
                                                this.changeTab("register")
                                            }
                                        >
                                            Sign Up
                                        </span>
                                    </p>

                                    <hr className="gradient closeMargin" />

                                    <Form.Item label="Email">
                                        {getFieldDecorator("email", {
                                            rules: [
                                                {
                                                    required: true,
                                                    type: "email",
                                                    message:
                                                        "Enter a valid Email.",
                                                    whitespace: true,
                                                },
                                                {
                                                    max: 60,
                                                    message:
                                                        "Email cannot be more than 60 characters.",
                                                },
                                            ],
                                        })(
                                            <Input
                                                autoFocus
                                                type="email"
                                                maxLength={60}
                                                size="large"
                                                onKeyDown={this.goNextField}
                                                enterKeyHint="next"
                                                placeholder="Enter your Email"
                                                onBlur={this.validateLogInForm}
                                            />
                                        )}
                                    </Form.Item>
                                    <Form.Item label="Password">
                                        {getFieldDecorator("password", {
                                            rules: [
                                                {
                                                    required: true,
                                                    message:
                                                        "Password must be at least 6 characters.",
                                                    whitespace: true,
                                                },
                                                {
                                                    min: 6,
                                                    message:
                                                        "Password must be at least 6 characters.",
                                                },
                                            ],
                                        })(
                                            <Input.Password
                                                type="password"
                                                size="large"
                                                onKeyDown={this.goNextField}
                                                enterKeyHint="next"
                                                placeholder="Enter your Password"
                                                onKeyUp={this.validateLogInForm}
                                            />
                                        )}
                                        <p
                                            className="paragraph-xs"
                                            style={{
                                                position: "absolute",
                                                right: 0,
                                                top: "25px",
                                            }}
                                        >
                                            <span
                                                className="changeTab red-text"
                                                onClick={() =>
                                                    this.changeTab("forgot")
                                                }
                                            >
                                                Forgotten Your Password?
                                            </span>
                                        </p>
                                    </Form.Item>

                                    <br />

                                    {this.state.showErrorMessage && (
                                        <div className="ant-alert-wrapper">
                                            <Alert
                                                style={{
                                                    display: this.state
                                                        .showErrorMessage
                                                        ? "block"
                                                        : "none",
                                                }}
                                                key="error-message"
                                                message={
                                                    this.state.errorMessage
                                                }
                                                type="error"
                                                showIcon={false}
                                            />
                                        </div>
                                    )}

                                    <Button
                                        type="primary"
                                        size="large"
                                        loading={this.state.loading}
                                        onClick={() => this.submitLogin()}
                                        disabled={
                                            this.state.logInButtonDisabled
                                        }
                                    >
                                        Log In
                                    </Button>

                                    <div className="registration-or">or</div>

                                    <div className="social-login-button-holder">
                                        <GoogleLoginButton
                                            {...this.props}
                                            context="Log In"
                                            toggleSocialLoginCheck={
                                                this.toggleSocialLoginCheck
                                            }
                                        />
                                    </div>
                                </Form>
                            </Tabs.TabPane>

                            <Tabs.TabPane tab="Forgot Password?" key="forgot">
                                <p
                                    className="heading-sm"
                                    style={{ marginBottom: "0.5em" }}
                                >
                                    Forget Your Password?
                                </p>
                                <p
                                    className="paragraph-lg"
                                    style={{ textAlign: "left" }}
                                >
                                    No worries, it happens!
                                </p>
                                <p
                                    className="paragraph-lg"
                                    style={{ textAlign: "left" }}
                                >
                                    Please enter your email address below and
                                    we'll send you a link to reset your
                                    password.
                                </p>
                                <Form id="forgot_form">
                                    <Form.Item label="Email">
                                        {getFieldDecorator("forgot_email", {
                                            rules: [
                                                {
                                                    required: true,
                                                    type: "email",
                                                    message:
                                                        "Enter a valid Email.",
                                                    whitespace: true,
                                                },
                                                {
                                                    max: 60,
                                                    message:
                                                        "Email cannot be more than 60 characters.",
                                                },
                                            ],
                                        })(
                                            <Input
                                                autoFocus
                                                type="email"
                                                maxLength={60}
                                                size="large"
                                                onKeyDown={this.goNextField}
                                                enterKeyHint="next"
                                                placeholder="Enter your Email"
                                                onBlur={this.validateForgotForm}
                                            />
                                        )}
                                    </Form.Item>

                                    {this.state.showErrorMessage && (
                                        <div className="ant-alert-wrapper">
                                            <Alert
                                                style={{
                                                    display: this.state
                                                        .showErrorMessage
                                                        ? "block"
                                                        : "none",
                                                }}
                                                key="error-message"
                                                message={
                                                    this.state.errorMessage
                                                }
                                                type="error"
                                                showIcon={false}
                                            />
                                        </div>
                                    )}

                                    <Button
                                        type="primary"
                                        size="large"
                                        loading={this.state.loading}
                                        onClick={() => this.submitLogin()}
                                        disabled={
                                            this.state.forgotButtonDisabled
                                        }
                                        style={{ marginTop: "25px" }}
                                    >
                                        Reset Password
                                    </Button>
                                </Form>
                            </Tabs.TabPane>
                        </Tabs>
                    </Col>
                    {!this.props.shell_student && (
                        <Col
                            id="loginModalRight"
                            xs={{ span: 0, offset: 0 }}
                            sm={{ span: 0, offset: 0 }}
                            md={{ span: 0, offset: 0 }}
                            lg={{ span: 12, offset: 0 }}
                            xl={{ span: 12, offset: 0 }}
                        >
                            <img
                                loading="lazy"
                                src={
                                    process.env.REACT_APP_SITE_URL +
                                    "/img/login-hero.webp"
                                }
                                alt={config.drivingSchoolLongName + " Student"}
                            />
                            <div id="loginModalTestimonial">
                                <p className="paragraph-xxl">
                                    "I had Rob Barilla at{" "}
                                    {config.drivingSchoolMediumName} and it was
                                    a great and fulfilling experience! The
                                    lessons were organised, enjoyable and
                                    helpful. Thanks to Rob for helping get my
                                    red P's"
                                </p>
                                <div className="name-stars">
                                    <p className="paragraph-xxl">- Gemma</p>
                                    <p className="stars">
                                        <FontAwesomeIcon
                                            key={"full-star-1"}
                                            fixedWidth
                                            icon={faStar}
                                            size="lg"
                                        />
                                        <FontAwesomeIcon
                                            key={"full-star-2"}
                                            fixedWidth
                                            icon={faStar}
                                            size="lg"
                                        />
                                        <FontAwesomeIcon
                                            key={"full-star-3"}
                                            fixedWidth
                                            icon={faStar}
                                            size="lg"
                                        />
                                        <FontAwesomeIcon
                                            key={"full-star-4"}
                                            fixedWidth
                                            icon={faStar}
                                            size="lg"
                                        />
                                        <FontAwesomeIcon
                                            key={"full-star-5"}
                                            fixedWidth
                                            icon={faStar}
                                            size="lg"
                                        />
                                    </p>
                                </div>
                            </div>
                        </Col>
                    )}
                </Row>
            </Modal>
        );
    }
}

const LoginModal = Form.create({ name: "login_form" })(Login);

export default withRouter(LoginModal);
