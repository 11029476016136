import React from "react";
import { withRouter } from "react-router";

//Ant Design Elements
import Button from "antd/lib/button";
import Form from "antd/lib/form";
import Input from "antd/lib/input";
import Modal from "antd/lib/modal";

import api from "../../../helpers/api";
import config from "../../../config";

class NewsletterFormClass extends React.Component {
    state = {
        saving: false,
        submitButtonDisabled: true,
    };

    validateForm = () => {
        this.props.form.validateFields((err, values) => {
            if (!err) {
                // No Errors : Disabled = False
                this.setState({
                    submitButtonDisabled: false,
                });
            } else {
                // Errors : Disabled = True
                this.setState({
                    submitButtonDisabled: true,
                });
            }
        });
    };

    goNextField = (e) => {
        if (e.key === "Enter") {
            e.preventDefault();
            let inputs = document.querySelectorAll(
                "#newsletter_form input, #newsletter_form textarea"
            );

            let lastElement = true;
            for (let i = 0; i < inputs.length; i++) {
                if (inputs[i] === e.target && !!inputs[i + 1]) {
                    lastElement = false;
                    document.activeElement.blur();
                    document.activeElement.removeAttribute("autofocus");

                    inputs[i + 1].setAttribute("autofocus", "autofocus");
                    inputs[i + 1].focus();
                }
            }
            if (lastElement) {
                this.submitForm();
            }
        }
    };

    submitForm = () => {
        this.props.form.validateFields((err, values) => {
            if (err) {
                return false;
            }

            this.setState(
                {
                    saving: true,
                },
                () => {
                    if (!!this.props.user.id) {
                        values.student_id = this.props.user.id;
                    }
                    api.post("newsletter", {
                        body: JSON.stringify(values),
                    })
                        .then((data) => {
                            if (
                                typeof data.result === "object" &&
                                data.result.success
                            ) {
                                Modal.success({
                                    className: "unavailable-survey",
                                    title: "Thank You. You're now subscribed.",
                                    content: (
                                        <p>
                                            Make sure to check your email
                                            regularly for your exclusive
                                            content.
                                        </p>
                                    ),
                                    icon: false,
                                    closable: false,
                                    keyboard: false,
                                    onOk: () => {
                                        if (this.props.callback && typeof this.props.callback === "function") {
                                            this.props.callback();
                                        }
                                    }
                                });
                            } else {
                                Modal.error({
                                    className: "unavailable-survey",
                                    title: "Error!",
                                    content:
                                        "Sorry, an error occurred. Please try again!",
                                    icon: false,
                                    closable: false,
                                    keyboard: false,
                                });
                            }
                            this.setState({
                                saving: false,
                            });
                        })
                        .catch((error) => {
                            Modal.error({
                                className: "unavailable-survey",
                                title: "Error!",
                                content:
                                    "Sorry, an error occurred. Please try again!",
                                icon: false,
                                closable: false,
                                keyboard: false,
                            });
                            this.setState({
                                saving: false,
                            });
                        });
                }
            );
        });
    };

    render() {
        const { getFieldDecorator } = this.props.form;

        let NewsletterFormToRender = (
                    <Form id="newsletter_form">
                        <p
                            className="paragraph-lg"
                            style={{
                                marginBottom: 0,
                            }}
                        >
                            <img
                                src={config.siteUrl + "/img/logo-ltrent.webp"}
                                height="35"
                                width="225"
                                alt={config.drivingSchoolMediumName + " Logo"}
                            />
                        </p>
                        <p
                            className="paragraph-sm"
                            style={{
                                marginBottom: 0,
                                textAlign: "center",
                            }}
                        >
                            Get your <u>FREE guide on best locations</u> to
                            learn to drive and more! Sign up to our newsletter.
                        </p>

                        <Form.Item label={false}>
                            {getFieldDecorator("email", {
                                rules: [
                                    {
                                        required: true,
                                        type: "email",
                                        message: "Enter a valid Email.",
                                        whitespace: true,
                                    },
                                    {
                                        max: 60,
                                        message:
                                            "Email cannot be more than 60 characters.",
                                    },
                                ],
                                initialValue: !!this.props.user.id && !this.props.shell_student ? this.props.user.email : "",
                            })(
                                <Input
                                    type="email"
                                    maxLength={60}
                                    size="large"
                                    onKeyDown={this.goNextField}
                                    enterKeyHint="next"
                                    placeholder="Email Address"
                                    onKeyUp={this.validateForm}
                                    disabled={this.state.saving}
                                    style={{
                                        marginTop: "5px",
                                    }}
                                />
                            )}
                        </Form.Item>

                        <Form.Item>
                            <Button
                                type="primary"
                                htmlType="submit"
                                size="large"
                                onClick={() => this.submitForm()}
                                disabled={
                                    this.state.submitButtonDisabled ||
                                    this.state.saving
                                }
                                loading={this.state.saving}
                                style={{
                                    width: "100%",
                                    marginTop: "1em",
                                }}
                            >
                                Subscribe
                            </Button>
                        </Form.Item>
                    </Form>
        );

        return NewsletterFormToRender;
    }
}

const NewsletterForm = Form.create({ name: "newsletter_form" })(
    NewsletterFormClass
);
export default withRouter(NewsletterForm);
